
<template>
  <div>
      <div class="top_top">
       <el-input
    placeholder="请输入饲草种类查询"
    clearable
    v-model="input2" @clear="getData">
         <el-button slot="append" icon="el-icon-search" @click="getData"></el-button>
  </el-input>
    </div>
    <el-table
      :data="data2"
      stripe
      style="width: 100%"
      :header-cell-style="{background:'#3275D6'}"
    >
      <el-table-column prop="kind" label="饲草种类"> </el-table-column>
      <el-table-column
        prop="artificialGrassSave"
        label="人工种草年末保
留面积（万亩）"
      >
      </el-table-column>
      <el-table-column
        prop="artificialGrassAdd"
        label="人工种草当年新
增面积（万亩）"
      >
      </el-table-column>
      <el-table-column prop="averageMmount" label="均产（公斤/亩）">
      </el-table-column>
      <el-table-column prop="totalYield" label="总产量（吨）"> </el-table-column>
      <el-table-column prop="silage" label="青贮量（吨）"> </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
          background
          @current-change="getData2"
          :current-page.sync="currentPage"
          :page-size="5"
          layout="prev, pager, next" :total.sync="totalNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 1,  // 当前页码
      input2:"",
      data2:[],
      totalNum:0
    }

  },
  methods:{
    getData(){
      this.$http({
        method:"post",
        url:"/tjjc/queryListTFP?condition="+this.input2+"&pageNo=1",
      }).then((res)=>{
        this.currentPage=1
        this.data2 =res.data;
        this.totalNum = res.data[0].sum
        console.log(res.data)
      }).catch((res)=>{
        console.log(res);
      })
    },
    getData2(){
      this.$http({
        method:"post",
        url:"/tjjc/queryListTFP?condition="+this.input2+"&pageNo="+this.currentPage,
      }).then((res)=>{
        this.data2 =res.data;
        this.totalNum = res.data[0].sum
        console.log(res.data)
      }).catch((res)=>{
        console.log(res);
      })
    }
  }
};
</script>
<style scoped>
.top_top{
  /* height: 40px; */
  width: 497px;
  margin-bottom: 24px;
}
::v-deep .el-table thead {
  color: #fff;
}
.bottom {
  text-align: right;
  margin-top: 30px;
}
::v-deep .el-table th.el-table__cell>.cell{
  padding-left: 20px;
}
::v-deep .el-table td.el-table__cell div{
   padding-left: 20px;
}
</style>
