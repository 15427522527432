
<template>
  <div style="flex: 1">
    <div id="main520" style="width: 450px; height: 400px"></div>
  </div>
</template>
<script>
export default {
  name: 'ForageSeed-1314520',
  props: {
    grassFarm0:{
      type: Number,
    },
    grassFarm1:{
      type: Number,
    },
    seedSalesAmount:{
      type: Number,
    }
  },
  methods: {
    myEcharts() {
      var myChart = this.$echarts.init(document.getElementById('main520'))
      //配置图表
      var option = {
  title: {
    // text: 'World Population'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01]
  },
  yAxis: {
    type: 'category',
    data: ['草种田面积',]
  },
  series: [
    {
      name: '2020',
      type: 'bar',
       barWidth : 30,
       itemStyle: {
              color: function (params) {
                let colorList = ["#5470C6"];
                return colorList[params.dataIndex];
              },
        },
      data: [18203]
    },
    {
      name: '2021',
      type: 'bar',
       barWidth : 30,
        itemStyle: {
              color: function (params) {
                let colorList = ["#91CC74"];
                return colorList[params.dataIndex];
              },
        },
      data: [19325]
    }
  ]
};
      myChart.setOption(option)
    },
  },
  mounted() {
    this.myEcharts()
  },
}
</script>

