
<template>
  <div>
    <div class="top_top">
       <el-input
    placeholder="请输入企业名称查询"
    clearable
    v-model="input2" @clear="getData">
         <el-button slot="append" icon="el-icon-search" @click="getData"></el-button>
  </el-input>
    </div>
    <el-table
      :data="data4"
      stripe
      style="width: 100%"
      :header-cell-style="{background:'#3275D6'}"
    >
      <el-table-column prop="name" label="企业名称" width="300" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column
        prop="baleYield"
        label="草捆产量（吨）"
      >
      </el-table-column>
      <el-table-column
        prop="grassBlockYield"
        label="草块产量（吨）"
      >
      </el-table-column>
      <el-table-column prop="grassPelletYield" label="草颗粒产量（吨）">
      </el-table-column>
      <el-table-column prop="mealYield" label="草粉产量（吨）"> </el-table-column>
      <el-table-column prop="silageProductionVolume" label="青贮生产量（吨）"> </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
          background
          @current-change="getData2"
          :current-page.sync="currentPage"
          :page-size="5"
          layout="prev, pager, next"
          :total.sync="totalNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input2:"",
      currentPage:1,
      data4:[],
      totalNum:0
    }
  },
  methods:{
    getData(){
      this.$http({
        method:"post",
        url:"/tjjc/queryListTGPC?condition="+this.input2+"&pageNo=1",
      }).then((res)=>{
        this.currentPage=1
        this.data4 = res.data
        this.totalNum = res.data[0].sum
      }).catch((res)=>{
        console.log(res);
      })
    },
    getData2(){
      this.$http({
        method:"post",
        url:"/tjjc/queryListTGPC?condition="+this.input2+"&pageNo="+this.currentPage,
      }).then((res)=>{
        this.data4 = res.data
        this.totalNum = res.data[0].sum
      }).catch((res)=>{
        console.log(res);
      })
    }
  }
};
</script>
<style scoped>
.top_top{
  /* height: 40px; */
  width: 497px;
  margin-bottom: 24px;
}
::v-deep .el-table thead {
  color: #fff;
}
.bottom {
  text-align: right;
  margin-top: 30px;
}
::v-deep .el-table th.el-table__cell>.cell{
  padding-left: 20px;
}
::v-deep .el-table td.el-table__cell div{
   padding-left: 20px;
}
</style>
