<template>
  <div>
    <div class="feed_box">
      <div class="box_box">
        <div class="feed_search">
          <div style="margin-top: 15px">
            <el-input
                placeholder="请输入企业名称/证书类型/证书编号查询"
                v-model="input3"
                class="input-with-select"
                width="600px"

            >
              <el-select
                  v-model="select"
                  slot="prepend"
                  placeholder="选择查询类型"
              >
                <el-option label="生产许可证书" value="1"></el-option>
                <el-option label="企业信息" value="2"></el-option>
                <el-option label="产品批准文号" value="3"></el-option>
                <el-option label="产品备案" value="4"></el-option>
              </el-select>
              <el-button slot="append" icon="el-icon-search" @click="queryTFC"></el-button>
            </el-input>
          </div>
        </div>
        <div class="feed_text">
          <p>说明：</p>
          <p>1、输入企业名称或证书编码、产品名称，选择具体类型，点击查询。</p>
          <p>
            2、查询结果最多显示10条与查询条件近似的记录，输入企业名称或证书编码、产品名称的详细信息，可以提高查询结果的准确性。
          </p>
          <p>
            3、可供查询的信息类型，包括但不限于以下类型。生产许可证书、企业信息、产品批准文号。
          </p>
        </div>
      </div>
      <div class="feed_table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="QYNAME" label="企业名称" width="300" :show-overflow-tooltip="true" v-if="select=='1'||select=='2'||select=='3'||select=='4'"></el-table-column>
          <el-table-column prop="BATCHNO" label="批准文号" width="180" v-if="select=='3'"></el-table-column>
          <el-table-column prop="LICTYPE" label="证书类型 " width="260" v-if="select=='1'||select=='4'"></el-table-column>
          <el-table-column prop="LICENCE_NUM" label="证书编号 " width="200" v-if="select=='1'||select=='3'||select=='4'"></el-table-column>
          <el-table-column prop="QY_CODE" label="企业统一信用代码 " width="300" v-if="select=='2'"></el-table-column>
          <el-table-column prop="QYPRODADDR" label="企业生产地址 " width="300" v-if="select=='1'||select=='2'"></el-table-column>
          <el-table-column prop="PRODNAME" label="产品名称" width="380"  v-if="select=='3'||select=='4'"></el-table-column>
          <el-table-column prop="ID" label="详情" v-if="select=='1'||select=='2'||select=='3'||select=='4'">
            <template slot-scope="{row}"><span @click="getDetail(row)" style="cursor:pointer;">详情</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <el-dialog title="详情" :visible.sync="dialogTableVisible">
        <div class="zhengshu">
          <div class="feed_one">
            <div>企业名称</div>
            <div v-model="companyName">{{ companyName }}</div>
          </div>
          <div class="feed_one">
            <div>证书编号</div>
            <div v-model="certificateNo">{{ certificateNo }}</div>
          </div>
          <div class="feed_one">
            <div>注册地址</div>
            <div v-model="registeAddress">{{ registeAddress }}</div>
          </div>
          <div class="feed_one">
            <div>生产地址</div>
            <div v-model="productionAddress">{{ productionAddress }}</div>
          </div>
          <div class="feed_one">
            <div>产品</div>
            <div v-model="production">{{ production }}</div>
          </div>
          <div class="feed_one">
            <div>法人</div>
            <div v-model="legalPerson">{{ legalPerson }}</div>
          </div>
          <div class="feed_one">
            <div>证书有效期始</div>
            <div v-model="createTime">{{ createTime }}</div>
          </div>
          <div class="feed_one">
            <div>证书有效期止</div>
            <div v-model="endTime">{{ endTime }}</div>
          </div>
          <div class="feed_one">
            <div>证书类型</div>
            <div v-model="certificateType">{{ certificateType }}</div>
          </div>
          <div class="feed_one">
            <div>证书状态</div>
            <div v-model="certificateStatus">{{ certificateStatus }}
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="详情" :visible.sync="dialogTableVisible2">
        <div class="zhengshu">
          <div class="feed_one">
            <div>企业名称</div>
            <div v-model="companyName">{{ companyName }}</div>
          </div>
          <div class="feed_one">
            <div>社会信用代码</div>
            <div v-model="certificateNo">{{ certificateNo }}</div>
          </div>
          <div class="feed_one">
            <div>注册地址</div>
            <div v-model="registeAddress">{{ registeAddress }}</div>
          </div>
          <div class="feed_one">
            <div>联系地址</div>
            <div v-model="productionAddress">{{ productionAddress }}</div>
          </div>
          <div class="feed_one">
            <div>法人</div>
            <div v-model="legalPerson">{{ legalPerson }}</div>
          </div>
          <div class="feed_one">
            <div>联系人</div>
            <div v-model="certificateStatus">{{ certificateStatus }}</div>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="详情" :visible.sync="dialogTableVisible3">
        <div class="zhengshu">
          <div class="feed_one">
            <div>企业名称</div>
            <div v-model="companyName">{{ companyName }}</div>
          </div>
          <div class="feed_one">
            <div>证书编号</div>
            <div v-model="certificateNo">{{ certificateNo }}</div>
          </div>
          <div class="feed_one">
            <div>产品</div>
            <div v-model="production">{{ production }}</div>
          </div>
          <div class="feed_one">
            <div>批准日期</div>
            <div v-model="createTime">{{ createTime }}</div>
          </div>
          <div class="feed_one">
            <div>批准文号</div>
            <div v-model="endTime">{{ endTime }}</div>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="详情" :visible.sync="dialogTableVisible4">
        <div class="zhengshu">
          <div class="feed_one">
            <div>企业名称</div>
            <div v-model="companyName">{{ companyName }}</div>
          </div>
          <div class="feed_one">
            <div>证书编号</div>
            <div v-model="certificateNo">{{ certificateNo }}</div>
          </div>
          <div class="feed_one">
            <div>产品</div>
            <div v-model="production">{{ production }}</div>
          </div>
          <div class="feed_one">
            <div>备案日期</div>
            <div v-model="createTime">{{ createTime }}</div>
          </div>
          <div class="feed_one">
            <div>产品标签图例</div>
            <div @click="gourl()" style="cursor:pointer;">查看</div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "Feed",
  data() {
    return {
      dialogTableVisible: false,
      dialogTableVisible2: false,
      dialogTableVisible3: false,
      dialogTableVisible4: false,
      input3: "",
      select: "",
      value: "",
      show: true,
      tableData: [],
      companyName:"",
      certificateNo:"",
      registeAddress:"",
      productionAddress:"",
      production:"",
      legalPerson:"",
      createTime:"",
      endTime:"",
      certificateType:"",
      certificateStatus:""
    };
  },
  methods: {
    queryTFC() {
      let params = new FormData();
      params.append("type",this.select);
      params.append("condition",this.input3)
      this.$http.post("/tjjc/queryFC",params).then((res) => {
        if(this.input3.trim()==""){
          this.$message("请输入查询内容进行查询")
        }else{
          this.tableData = res.data
        }

      })
    },
    getDetail(row) {
      if(this.select=='2'){
        this.dialogTableVisible2=true;
        this.companyName = row.QYNAME;
        this.certificateNo = row.QY_CODE;
        this.legalPerson = row.FR_NAME;
        this.registeAddress = row.QYPRODADDR;
        this.productionAddress = row.LINKADDR;
        this.certificateStatus = row.LINKMAN
      }else if(this.select=='3'){
        this.dialogTableVisible3=true;
        this.companyName = row.QYNAME;
        this.certificateNo = row.LICENCE_NUM;
        this.production = row.PRODNAME;
        this.createTime = row.PUBDATE;
        this.endTime = row.FILEDES;
      }else if(this.select=='4'){
        this.dialogTableVisible4=true;
        this.companyName = row.QYNAME;
        this.certificateNo = row.LICENCE_NUM;
        this.production = row.PRODNAME;
        this.createTime = row.PUBDATE;
        this.endTime = row.FILEDES;
      }else{
        this.dialogTableVisible=true;
        let url = "/tjjc/queryFCD?id=" + row.ID+"&type="+this.select
        this.$http({
          url: url,
          method: "post"
        }).then((res) => {
          this.companyName = res.data.companyName;
          this.certificateNo = res.data.certificateNo;
          this.registeAddress = res.data.registeAddress;
          this.productionAddress = res.data.productionAddress;
          this.production = res.data.production;
          this.legalPerson = res.data.legalPerson;
          this.createTime = res.data.createTime;
          this.endTime = res.data.endTime;
          this.certificateType = res.data.certificateType;
          this.certificateStatus = res.data.certificateStatus;
        })
    }
    },
    gourl() {
      const certUrl = "https://slxkcx.nahs.org.cn/"+this.endTime;
      window.open(certUrl)
    }

  }
};
</script>
<style scoped>
.box_box {
  box-shadow: 0px 2px 51px 17px rgba(0, 47, 168, 0.08);
  border-radius: 4px;
}
::v-deep .el-dialog__body{
  font-size: 16px;
  padding-left: 0px;
  padding-right: 0px;
}
.feed_search {
  background: #fff;
  padding: 20px;
  padding-top: 1px;
}

.feed_box {
  width: 1200px;
  margin: auto;
}

.feed_title {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  margin-top: 50px;
}

::v-deep .el-select .el-input {
  width: 130px;
}

::v-deep .el-input__inner:nth-child(2) {
  width: 600px;
}

::v-deep .el-input__inner:nth-child(3) {
  width: 600px;
}

.feed_text {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  padding: 19px;
  padding-top: 12px;
  background: #fff;

}

.feed_text p {
  line-height: 26px;
}

.feed_table {
  background-color: #F6F9FC;
  margin-top: 30px;

}

::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: 0px;
}

::v-deep .el-dialog__header {
  text-align: center;
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275D6;
}

.feed_one {
  display: flex;

  padding: 15px;
}

.feed_one:nth-child(odd) {
  background: #F6F9FC;
}

.zhengshu {
  padding: 0 80px;
}

.feed_one div:nth-child(1) {

  flex: 1;
}
.feed_one div:nth-child(2) {
flex: 3;
  text-align: left;
}
::v-deep .el-table--fit{
  padding-left: 12px;
}
::v-deep .el-input-group {
  width: 500px;
}

::v-deep .el-dialog {
  width: 779px !important;
  height: auto !important;
}

</style>
