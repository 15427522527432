<template>

    <div class="contentWrap">
    <div class="ass_header">
      <div class="ass_content">
        <div class="item1">
          <div class="title">冬闲田</div>
          <div class="desc"><span>{{ data5.winterGrass }}</span><span>万亩</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">夏秋闲田</div>
          <div class="desc"><span>{{ data5.summerGrass }}</span><span>万亩</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">果园隙地</div>
          <div class="desc"><span>{{ data5.orchardGrass }}</span><span>万亩</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">四边地</div>
          <div class="desc"><span>{{ data5.fourSidesGrass }}</span><span>万亩</span></div>
        </div>
        <div class="line"></div>
        <div class="item1">
          <div class="title">其他</div>
          <div class="desc"><span>{{ data5.otherGrass }}</span><span>万亩</span></div>
        </div>
      </div>
    </div>
    <div class="contentMain" v-if="showEcharts">
      <Echarts :winterGrass="data5.winterGrass" :summerGrass="data5.summerGrass" :orchardGrass="data5.orchardGrass" :fourSidesGrass="data5.fourSidesGrass" :otherGrass="data5.otherGrass"/>
      <div class="All">
        <div class="add">
          <div class="heading">累计</div>
          <div class="quantity"><span>{{ data5.farmlandGrass }}</span><span>万亩</span></div>
        </div>
      </div>
    </div>


   <div>
      <!-- <Feed></Feed> -->
    </div>
 </div>
</template>

<script>
import Feed from "./components/feedcertificate.vue"
import Echarts from './components/echart.vue'
// import echart0 from "@/views/grasslanddata/components/echart0";
export default {
  components: {
    Echarts,
    Feed,
  },
  data(){
    return{
      showEcharts:false,
      data5:[],
    }
  },
  methods: {
    getData(){
      //农闲田种草
      this.$http({
        method:"post",
        url:"/tjjc/queryTFG",
      }).then((res)=>{
        this.data5 =res.data
        this.showEcharts = true
      }).catch((res)=>{
        console.log(res);
      })
    }
  },
};
</script>

<style lang="less" scoped>
.contentWrap {
  // background: #f6f9fc;
}
.ass_content {
  display: flex;
  // align-items: center;
  justify-content: center;
}
.item1 {
  // width: 212px;
  // margin-right: 20px;
  // padding: 30px 0 15px 30px;
  box-sizing: border-box;
  flex: 1;
}
// .line {
//   width: 1px;
//   height: 62px;
//   background: #e1e1e1;
//   margin: 20px 10px;
// }
.title {
  margin-bottom: 18px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
.desc span:nth-child(1) {
  font-size: 16px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275D6;
}
.desc span:nth-child(2) {
  display: inline-block;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  // margin-left: 10px;
}
.contentMain {
  display: flex;
  // width: 1200px;
  margin: 0 auto;
}
.All {
  width: 195px;
  height: 118px;

background: #F4F9FF;
 border-radius: 6px;
  padding: 18px 0 0px 15px;
  box-sizing: border-box;
 margin-top: 60px;
}
.heading {
  margin-bottom: 10px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
.quantity span:nth-child(1) {
  font-size: 30px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
}
.quantity span:nth-child(2) {
  margin-left: 10px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
</style>
