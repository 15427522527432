<template>

    <div class="NaturalGrass-box1">
      <div>
        <div class="NaturalGrass-box2">
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">草种田面积</main>
            <p class="NaturalGrass-text2">
              {{grassFarm0}}
               <span class="NaturalGrass-text3">万亩</span>
            </p>


          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">草场采种量</main>
            <p class="NaturalGrass-text2">
              {{seedCollection0}}
              <span class="NaturalGrass-text3">吨</span>
            </p>


          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">均产</main>
            <p class="NaturalGrass-text2">
              {{averageProduction}}
              <span class="NaturalGrass-text3">公斤/亩</span>
            </p>


          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">草种生产量</main>
            <p class="NaturalGrass-text2">
              {{seedProductAmount0}}
              <span class="NaturalGrass-text3">吨</span>
            </p>


          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">草种销售量</main>
            <p class="NaturalGrass-text2">
              {{seedSalesAmount1}}
               <span class="NaturalGrass-text3">吨</span>
            </p>


          </div>
        </div>

        <div class="NaturalGrass-box5">
         <div
            id="main520"
            class="NaturalGrass-box6"
            style="width: 430px; height: 400px"
            v-if="showEcharts"
          >
           <echar520 :grassFarm0="grassFarm0" :grassFarm1="grassFarm1"/>
          </div>
          <div
            id="main1314"
            class="NaturalGrass-box6"
            style=" height: 400px"
          >
           <echar1314520 ref="echar1314520" :seedCollection0="seedCollection0" :seedProductAmount0="seedProductAmount0" :seedSalesAmount1="seedSalesAmount1"  :seedCollection1="seedCollection1" :seedProductAmount1="seedProductAmount1" :seedSalesAmount0="seedSalesAmount0"/>
          </div>
        </div>
      </div>


     <div>

      </div>
  </div>
</template>
<script>
import echar520 from "./components/ForageSeedLeft.vue"
import echar1314520 from "./components/ForageSeedRight.vue"
export default {
  name: "ForageSeed",
  components: {
    echar520,
    echar1314520,
  },
  data(){
    return{
      showEcharts:false,
      data3:[],
      grassFarm0:0,
      grassFarm1:0,
      seedCollection0:0,
      seedProductAmount0:0,
      seedSalesAmount1:0,
      seedCollection1:0,
      seedProductAmount1:0,
      seedSalesAmount0:0,
      averageProduction:0
    }
  },
  methods: {
    getData(){
      //饲草种子加工
      this.$http({
        method: "post",
        url: "/tjjc/queryTSP",
      }).then((res) => {
        this.data3 = res.data
        this.grassFarm0 = res.data[0].grassFarm
        this.grassFarm1 = res.data[1].grassFarm
        this.seedCollection0 = res.data[0].seedCollection
        this.seedCollection1 = res.data[1].seedCollection
        this.seedProductAmount0 = res.data[0].seedProductAmount
        this.seedProductAmount1 = res.data[1].seedProductAmount
        this.seedSalesAmount0 = res.data[0].seedSalesAmount
        this.seedSalesAmount1 = res.data[1].seedSalesAmount
        this.averageProduction = res.data[0].averageProduction
        this.showEcharts = true
        this.$refs.echar1314520.myEcharts(this.seedCollection0,this.seedProductAmount0,this.seedSalesAmount0,this.seedCollection1,this.seedProductAmount1,this.seedSalesAmount1);
      }).catch((res) => {
        console.log(res);
      })
    },
  },
};
</script>
<style scoped>
.NaturalGrass-box3 p{
    color: #3275D6;
}
/* 最外盒子样式 */
.NaturalGrass-box1 {
  width: 100%;
  /* height: 600px; */
  /* background: #f6f9fc; */
  /* display: flex; */
  justify-content: center;
}
/* 包裹文字盒子的盒子样式 */
.NaturalGrass-box2 {
  /* width: 1140px; */
  height: 99px;
  /* background: #f6f9fc; */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;

}
/* 包裹文字盒子样式 */
.NaturalGrass-box3 {
  /* width: 212px; */
  /* height: 99px; */
}
/* 盒子内文字样式 */
.NaturalGrass-text1 {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
  /* margin-top: 20px;
  margin-left: 30px; */
}
.NaturalGrass-text2 {
  font-size: 16px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  /* margin-left: 30px; */
  margin-top: 20px;
}
.NaturalGrass-text3 {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  padding-bottom: 10px;
  display: inline-block;
font-weight: 400;
}
/* .NaturalGrass-box4 {
  width: 0px;
  height: 62px;
  border: 1px solid #e1e1e1;
  display: block;
} */
/* 图形上方文字 */
.NaturalGrass-box5 p {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
/* 放置图形的盒子 */
.NaturalGrass-box5 {
  /* margin-top: 50px;
  margin-left: 30px; */
  justify-content: center;
  display: flex;
}
/* 图形盒子 */
.NaturalGrass-box6 {
  display: inline-block;
  margin-top: 14px;
  flex: 1;
}
.NaturalGrass-box6:nth-child(1){
  /* margin-right: 30px; */
}
</style>
