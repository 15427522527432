<template>
    <div>
         <div class="login_top">
      <div class="logintop_min">
        <div class="min_top">
          <div class="top_img1">
            <img src="../../assets/logo.png" alt="" />
          </div>
          <div class="top_img2">
            <img src="../../assets/平台名称.png" alt="" />
          </div>
          <div class="geren" v-if="visable">
          <div class="geren_box">
              <div class="gerenone">
              <img src="../../assets/geren.png" alt="" />
              欢迎您：{{sysUser.FULLNAME}}
              <img src="../../assets/index.png" alt="" class="caidan" @click="goIndex"/>
              <img src="../../assets/713.png" alt="" class="caidan" @click="goUserInfo"/>
              <img
                src="../../assets/black.png"
                alt=""
                class="caidan"
                @click="signout"
              />
            </div>
            <div class="geren_btn">
              <div class="one" v-for="(itemson, indexson) in sonList" :key="itemson.indexson" v-if="itemson.superCode === 50"
                  @click="goUrl(itemson)">{{itemson.pname}}</div>
              <!-- <div class="one">统一用户管理</div>
              <div class="one">中央数据库管理</div> -->
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script>
import { time } from 'echarts';
export default {
  name:'top',
  data() {
    return {
      sysUser: {},
      visable: true,
      timer: null,
      menuList:[],
    };
  },
  created() {
    this.menuList = JSON.parse(decodeURIComponent(localStorage.getItem('menu')))
    this.getSysUser();
    this.handleMenuList();
  },
  methods: {
    handleMenuList(){
      let lev1 = []
      let lev2 = []
      this.menuList.forEach(function (item, index) {
        if((item.superCode) === 0){
          lev1.push(item)
        }else{
          lev2.push(item)
        }
      });
      this.fatherList = lev1 
      this.sonList = lev2
    },
     goUrl(item) {
        this.ins=item
        //获取sysuser
        this.$http({
          method: "post",
          url: "/home/getUser",
        })
        .then(res=>{
          if(res.data.AREA === null || res.data.PHONE === null || res.data.ORG_NAME === null || res.data.PERSON_TYPE ===null || res.data.FULLNAME === null){
            this.$confirm('完善个人信息后才能访问该模块!', '提示', {
              confirmButtonText: '去完善个人信息',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push('/UserCenter')
            }).catch(() => {
              this.$message({
                showClose: true,
                type: 'info',
                message: '已取消'
              });
            });
          }else{
            let params = new FormData();
            params.append("menu", item);
            this.$http.post("/home/recordOauthLog", params, {
              headers: { "Content-Type": "application/json" },
            }).then((res) => {});
            window.open(item.url)
          }
        }).catch(res=>{
          console.log(res)
        });
      },
      handleMenu() {
        let lev1 = [];
        let lev2 = [];
        let lev3 = [];
        let lev4 = [];
        let lev5 = [];
        let lev6 = [];
        let lev7 = [];
        this.sonList.forEach(function (item, index) {
            if (item.superCode === 10) {
                lev1.push(item);
            }
            if (item.superCode === 20) {
                lev2.push(item);
            }
            if (item.superCode === 30) {
                lev3.push(item);
            }
            if (item.superCode === 40) {
                lev4.push(item);
            }
            if (item.superCode === 50) {
                lev5.push(item);
            }
            if (item.superCode === 60) {
                lev6.push(item);
            }
            if (item.superCode === 70) {
                lev7.push(item);
            }
        });
        this.menu1 = lev1;
        this.menu2 = lev2;
        this.menu3 = lev3;
        this.menu4 = lev4;
        this.menu5 = lev5;
        this.menu6 = lev6;
        this.menu7 = lev7;
    },
    getUserStatus(){
      this.$http({
        method: "get",
        url: "/home/getUserStatus",
      })
      .then((res) => {
        if(res.errorCode === 2001){
          if(this.$route.path !== '/'){
            this.$router.push("/")
          }else{
            console.log(res)
          }
        }
      })
      .catch((res) => {
        console.log(res);
      });
    },
    chageView(str) {
      this.comData = str;
    },
    //获取sysuser
    getSysUser() {
      this.$http({
        method: "post",
        url: "/home/getUser",
      })
      .then((res) => {
        this.sysUser = res.data
        if(res.errorCode === 2001){
          this.visable = true
        }else{
          this.visable = true
        }
      })
      .catch((res) => {
        console.log(res);
      });
    },
    //退出
    signout() {
      this.$http
      .post("/signout")
      .then((res) => {
        this.$router.push('/')
      })
      .catch((res) => {});
    },
    //去用户信息维护
    goUserInfo(){
      this.$router.push('/UserCenter')
    },
    //去首页
    goIndex(){
      this.$router.push('/Portalbackup')
    }
  }
}
</script>
<style scoped>
.login_top {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/头部底图.png);
  background-size: 100% 100%;
}
.logintop_min {
  width: 1200px;
  margin: auto;
}
.min_top {
  height: 160px;
  /* line-height: 160px; */
  display: flex;
}
.top_img1 {
  display: flex;
  align-items: center;
}
.top_img2 {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
  /* flex: 1; */
  margin-right: 70px;
}
.geren{
  flex: 1;
  /* display: flex; */
}

.gerenone {
  max-width: 276px;
  margin-top: 32px;
  height: 40px;
  display: flex;
  background-color: #fff;
  /* margin-top: 110px; */
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.8;
margin-left: 132px;
  /* position: relative; */
  /* margin-left: 100px; */
}
.gerenone img {
  width: 20px;
  height: 20px;
  padding: 0 10px;
}
.caidan{
  cursor: pointer;
}
.geren_btn{
  display: flex;
  margin-top: 21px;
  justify-content: flex-end;
}
.one{
padding: 8px 12px;
/* height: 32px; */
background: #FFFFFF;
border-radius: 4px;
font-size: 16px;
font-family: Microsoft YaHei;
font-weight: 300;
color: #333;
display: flex;
align-items: center;
justify-content: center;
margin-left: 16px;
cursor: pointer;
}
.one:nth-child(1){
  margin-left: 0;
}
.one:hover{
  background: #3275D6;
  color: #fff;
}
</style>