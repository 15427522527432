<template>
  <div>
    <div id="mainone" style="width: 623px; height: 400px"></div>
  </div>
</template>
<script>
export default {
  name: "Echarts",
  props: {
    winterGrass: {
      type: Number,
    },
    summerGrass:{
      type: Number,
    },
    orchardGrass:{
      type: Number,
    },
    fourSidesGrass:{
      type: Number,
    },
    otherGrass:{
      type: Number,
    },

  },
  methods: {
    myEcharts() {
      var myChart = this.$echarts.init(document.getElementById("mainone"));
      //配置图表
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          left: "0%",
          right: "4%",
          bottom: "7%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["冬闲田", "夏秋闲田", "果园隙地", "四边地", "其他"],
            // axisTick: {
            //   alignWithLabel: true,
            // },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "万亩",
            min: 0,
            max: 4000,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "面积(万亩)",
            type: "bar",

            barWidth: "43px",
            barHeight: "100%",
            data: [this.winterGrass, this.summerGrass, this.orchardGrass, this.fourSidesGrass, this.otherGrass],
            itemStyle: {
              color: function (params) {
                let colorList = [
                  "#5470C6",
                  "#91CC74",
                  "#FAC758",
                  "#EE6666",
                  "#73C0DE",
                  "#5470C6",
                  "#EE6666",
                ];
                return colorList[params.dataIndex];
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.myEcharts();
  },
};
</script>
