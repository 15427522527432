<template>
    <div id="main0" style="width: 500px !important; height: 400px"></div>
</template>
<script>

export default {
  name: "Echarts3",
    props: {
        grasslandToAccount: {
            type: Number,
        },
        grasslandToJointAccount:{
            type: Number,
        },
        grasslandOtherForms:{
            type: Number,
        }
    },
  methods: {
    myEcharts() {
      var myChart = this.$echarts.init(document.getElementById("main0"));
      //配置图表
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "7%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["承包到户", "承包到联户", "其他"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            name: "万亩",
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "50px",
            barHeight: "500px",
            data: [this.grasslandToAccount, this.grasslandToJointAccount, this.grasslandOtherForms],
            itemStyle: {
              color: function (params) {
                let colorList = ["#5470C6", "#73C0DE", "#91CC74"];
                return colorList[params.dataIndex];
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.myEcharts();
  },
};
</script>

