<template>

    <div class="NaturalGrass-box1">
      <div>
        <div class="NaturalGrass-box2">
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">当年青贮量</main>
            <p class="NaturalGrass-text2">
              {{data1.reserveOfYear}}
               <span class="NaturalGrass-text3">万吨</span>
            </p>

          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">打井数量</main>
            <p class="NaturalGrass-text2">
              {{data1.wellNum}}
              <span class="NaturalGrass-text3">个</span>
            </p>

          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">草场灌溉面积</main>
            <p class="NaturalGrass-text2">
                {{data1.irrigationOfPasture}}
                 <span class="NaturalGrass-text3">万亩</span>
            </p>

          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">井灌面积</main>
            <p class="NaturalGrass-text2">
                {{data1.irrigationOfWell}}
                 <span class="NaturalGrass-text3">万亩</span>
            </p>

          </div>
          <div class="NaturalGrass-box4"></div>
          <div class="NaturalGrass-box3">
            <main class="NaturalGrass-text1">定居点牲畜棚圈面积</main>
            <p class="NaturalGrass-text2">
                {{data1.livestockBarn}}
                <span class="NaturalGrass-text3">万平方米</span>

            </p>
          </div>
        </div>

        <div class="NaturalGrass-box5">
          <div
            id="main0"
            class="NaturalGrass-box6"
            style=" height: 400px"
            v-if="showEcharts"
          >
           <echart0 :grasslandToAccount="data1.grasslandToAccount" :grasslandToJointAccount="data1.grasslandToJointAccount" :grasslandOtherForms="data1.grasslandOtherForms"/>

          </div>
          <div class="grassland">
            <div class="add">
              <div class="heading">累计</div>
              <div class="quantity"><span>{{ data1.grassland }}</span><span>万亩</span></div>
            </div>
          </div>
        </div>

      </div>


     <div>

      </div>
  </div>
</template>
<script>
import echart0 from "./components/echart0.vue"
export default {
  name: "Echarts3",
  components: {
    echart0,
  },
  data(){
    return{
        showEcharts:false,
    }
  },
  props: ["data1"],
  methods: {
    ifshow(){
      //天然饲草利用
      this.showEcharts = true
    },
  },
};
</script>
<style scoped>
.All {
  width: 195px;
  height: 118px;

  background: #F4F9FF;
  border-radius: 6px;
  padding: 30px 0 0px 30px;
  box-sizing: border-box;
  margin-top: 60px;
}
.heading {
  margin-bottom: 10px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
.quantity span:nth-child(1) {
  font-size: 30px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
}
.quantity span:nth-child(2) {
  margin-left: 10px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
/* 最外盒子样式 */
.NaturalGrass-box1 {
  width: 100%;
  /* height: 600px; */
  /* background: #f6f9fc; */
  /* display: flex; */
  justify-content: center;
}
/* 包裹文字盒子的盒子样式 */
.NaturalGrass-box2 {
  /* width: 1140px; */
  height: 99px;
  /* background: #f6f9fc; */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;

}
/* 包裹文字盒子样式 */
.NaturalGrass-box3 {
  /* width: 212px; */
  /* height: 99px; */
}
.NaturalGrass-box3 p{
    color: #3275D6;
}
/* 盒子内文字样式 */
.NaturalGrass-text1 {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
  /* margin-top: 20px;
  margin-left: 30px; */
}
.NaturalGrass-text2 {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #333333;
  /* margin-left: 30px;*/
  margin-top: 20px;
}
.NaturalGrass-text3 {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  /* padding-bottom: 10px; */
  display: inline-block;
  font-weight: 400;
  /* margin-left: 30px; */
}
.NaturalGrass-box4 {
  width: 0px;
  height: 62px;
  /*border: 1px solid #e1e1e1;*/
  display: block;
}
/* 图形上方文字 */
.NaturalGrass-box5 p {
  height: 16px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
/* 放置图形的盒子 */
.NaturalGrass-box5 {
  /* margin-top: 50px;
  margin-left: 30px; */
  justify-content: center;
  display: flex;
}
/* 图形盒子 */
.NaturalGrass-box6 {
  display: inline-block;
  margin-top: 14px;
  flex: 1;
}
.NaturalGrass-box6:nth-child(1){
  /* margin-right: 30px; */
}
.grassland{
  width: 195px;
  height: 118px;
flex: .4;
  background: #F4F9FF;
  border-radius: 6px;
  padding: 30px 0 0px 30px;
  box-sizing: border-box;
  margin-top: 60px;
}
</style>
