<template>
    <div>
        <div class="grass_min_box">
              <div class="grass_nav">
                <div class="grass_nav_one" :class="{active:show==1}" @click="getData(1)">天然饲草利用</div>
                <div class="grass_nav_one" :class="{active:show==2}" @click="getData(2)">饲草生产</div>
                <div class="grass_nav_one" :class="{active:show==3}" @click="getData(3)">饲草种子生产</div>
                <div class="grass_nav_one" :class="{active:show==4}" @click="getData(4)">草产品加工企业生产</div>
                <div class="grass_nav_one" :class="{active:show==5}" @click="getData(5)">农闲田种草</div>
              </div>
              <div class="grass_right">
                <!-- 天然饲草利用 -->
               <Seedproduction ref="Seedproduction" v-show="show==1" :data1=data1></Seedproduction>
                <!-- 饲草生产 -->
                <GrassProcessing ref="GrassProcessing" v-show="show==2"></GrassProcessing>
                <!-- 饲草种子生产 -->
                <ForageSeed ref="ForageSeed" v-show="show==3"></ForageSeed>
                <!-- 草产品加工企业生产 -->
                <GrassSeed ref="GrassSeed" v-show="show==4"></GrassSeed>
                   <!-- 农闲田种草 -->
                <Assembly ref="Assembly" v-show="show==5"></Assembly>
              </div>
            </div>

    </div>
</template>
<script>
import ForageSeed from "./ForageSeed.vue";
import GrassProcessing from "./GrassProcessing.vue";
import Assembly from "./assembly.vue";
import GrassSeed from "./GrassSeed.vue";
import Seedproduction from "./seedproduction.vue";
import Feedcertificate from "./components/feedcertificate.vue";
export default {
  components: {
    ForageSeed,
    Assembly,
    GrassProcessing,
    GrassSeed,
    Feedcertificate,
    Seedproduction
  },
  data() {
    return {
      show:1,
      data1:[],
    };
  },
  created(){
    this.initData();
  },
  methods: {
    initData(){
      //天然饲草利用
      this.$http({
        method: "post",
        url: "/tjjc/queryTFU",
      }).then((res) => {
        this.data1 = res.data
        this.$refs.Seedproduction.ifshow();
      }).catch((res) => {
        console.log(res);
      });
    },
    getData(activeShow){
      this.show = activeShow
      if(this.show == 1){
        this.initData();
      }

      if(this.show == 2){
        this.$refs.GrassProcessing.getData();
      }

      if(this.show == 3){
        this.$refs.ForageSeed.getData();
      }

      if(this.show == 4){
        this.$refs.GrassSeed.getData();
      }

      if(this.show == 5){
        this.$refs.Assembly.getData();
      }
    },
  },
};
</script>
<style scoped>

.grass_min_box {
  width: 1160px;
  margin: auto;
  display: flex;
  padding: 0 20px;
    padding-top: 40px;
    background: #fff;
    /* box-shadow: 0px 2px 51px 17px rgba(0, 47, 168, 0.08); */
border-radius: 4px;
}
.grass_nav {
  width: 212px;
  margin-right: 20px;
}
.grass_nav_one {
  border-radius: 4px;
  border: 1px solid #dddddd;
  height: 64px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
  width: 212px;
}
.grass_right {
  flex: 1;
}
.active{
  background: #E9F4FF;
border-radius: 4px;
border: 1px solid #3275D6;
font-size: 18px;
font-family: MicrosoftYaHei;
color: #3275D6;
}
</style>
