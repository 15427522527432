<template>
  <div style="flex: 1">
    <div id="main1314520" style="width: 450px; height: 400px"></div>
  </div>
</template>
<script>
export default {
  name: 'ForageSeed-1314520',
  props: {
    // seedCollection0: {
    //   type: Number,
    // },
    // seedProductAmount0:{
    //   type: Number,
    // },
    // seedSalesAmount0:{
    //   type: Number,
    // },
    // seedCollection1: {
    //   type: Number,
    // },
    // seedProductAmount1:{
    //   type: Number,
    // },
    // seedSalesAmount1:{
    //   type: Number,
    // }
  },
  methods: {
    myEcharts(seedCollection0,seedProductAmount0,seedSalesAmount0,seedCollection1,seedProductAmount1,seedSalesAmount1) {
      console.log(seedCollection1,seedProductAmount0)
      var myChart = this.$echarts.init(document.getElementById('main1314520'))
      //配置图表
      var option = {
          tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    legend: {
        top: 'bottom',
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '8%',
        containLabel: true
    },
    yAxis: {
        type: 'value',
         name: '吨',
          data: ['1034', '2512', '5761'],
        splitLine: {
            show: true,
            lineStyle: {
              color: '#E1E1E1FF',
            }
          },
          axisLine: {
            lineStyle: {
              color: '#E1E1E1FF'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#000'
          }
    },
    xAxis: {
        type: 'category',
        name: '数据名称',
        data: ['草场采种量', '草种生产量', '草种销售量'],
        axisLine: {
            lineStyle: {
              color: '#5470C6'
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#000'
          }
    },
    series: [
        {
            name: '去年',
            type: 'bar',
            barWidth: 30,
            barGap: 0,
            data: [seedCollection1, seedProductAmount1, seedSalesAmount1],
            itemStyle: {
              color: function (params) {
                let colorList = ["#BBC6E8", "#BCE7F3", "#D3EBC7"];
                return colorList[params.dataIndex];
              },
                borderRadius:  [0, 0, 0, 0],
            }
        },
        {
            name: '今年',
            type: 'bar',
            barWidth: 30,
            barGap: 0,
          data: [seedCollection0, seedProductAmount0, seedSalesAmount0],
            itemStyle: {
                color: function (params) {

                let colorList = ["#5470C6", "#73C0DE", "#91CC74"];
                return colorList[params.dataIndex];
              },
            }
        }
    ],
      }
      myChart.setOption(option)
    },
  },
  // mounted() {
  //
  //   this.$nextTick(()=>{
  //     console.log("3333333333333")
  //     this.myEcharts()
  //   });
  //
  // },
}
</script>
